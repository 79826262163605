
.cartDropdown{
    position:absolute;
    visibility:hidden;
    top:100%;
    right: -28px;
    background-color:#fff;
    width: 350px;
    margin-top:3px;
    border-radius: 12px;
    -webkit-box-shadow: 0 10px 70px rgba(0,0,0,0.15);
    box-shadow: 0 10px 70px rgba(0,0,0,0.15);
    z-index: 9999;
    overflow: hidden;
    transition:all .15s ease-in-out;
    opacity: 0;
}

.cartDropdown.visible{
    opacity: 1;
    visibility:visible;
}

.cartDropdown > div{
    position: relative;
    max-height: 450px;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.cartDropdown > div::-webkit-scrollbar {
    display: none;
}

.cartDropdownHeader{
    position:sticky;
    top:0;
    z-index: 999;
    background: #fff;
    text-align: center;
    padding: 20px 15px 5px 15px;
}

.cartDropdownHeader h4{
    font-size:18px;
    font-weight: 700;
    text-transform: uppercase;
}

.cartDropdownFooter{
    position:sticky;
    bottom:0;
    background-color: #fff;
    padding:20px 15px;
}

.footerRow{
    display: flex;
    justify-content:space-between;
    align-items: center;
}

.footerRow p{
    margin-bottom: 5px;
    font-size: 14px;
    font-weight:600;
    text-transform: uppercase;
    line-height: 2;
}

.footerRow p.shipmentPrice{
    font-weight: 500;
}

.footerRow p.totalPriceLabel,.footerRow p.totalPriceLabel + p{
    font-size: 16px;
    font-weight: 600;
}
.footerRow p.totalPriceLabel span{
    color: var(--color-dark-grey);
}



@media (max-width:560px) {
    .cartDropdown{
        width:300px;
    }
}

.emptyCart{
    padding:  15px 15px 25px 15px;
    color:var(--color-dark-grey);
    text-align: center;
    height:120px;
}
.emptyCart h4{
    font-size: 1.2rem;
    font-weight:700;
    margin-bottom:5px;

}
.emptyCart p{
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 600;
}

