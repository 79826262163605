
.productAddonItem{
    padding:10px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    min-width:100%;
}

.productAddonItem:not(:last-child)
{
    border-bottom: 1px solid var(--color-light-grey);
}

.radioSelector{
    margin-left: auto;
    margin-right: 20px;
}
.productAddonItemImg{
    height:50px;
    width:50px;
    overflow:hidden;
    border-radius: 5px;
}

.productAddonItemInfo{
    margin-top:3px;
    display:flex;
    flex-direction:column;
    /*justify-content:space-between;*/
    margin-left:10px
}
.productAddonItemInfo h6{
    font-size:.85rem;
    font-weight:500;
    margin: 0 0 3px 0;
    color: #444;
}

.productAddonItemPrice {
    color: var(--color-dark-grey) !important;
    font-weight:500 !important;
    margin: 4px 0 0 0;

}

@media(max-width:576px)
{
    .productAddonItemInfo h6{
        font-size:.7rem;
        margin: 0 0 2px 0;
    }
}