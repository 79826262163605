
.cartItem{
    padding: 10px 15px;
    display:flex;
    flex-direction:column;
    border-bottom: 1px solid #d0d1d3;
}

.cartItem > div{
    display: flex;
}

.cartDropdownImage,
.cartDropdownImage > div{
    width:70px;
}

.productInformation{
    margin-left: 8px;
    color:var(--color-dark-grey);

}

.productInformation h6{
    font-size:11px;
    margin-bottom: 0;
    font-weight:700;
}

.productInformation p{
    margin-bottom: 0;
    font-size:11px;
    font-weight: 500;
    line-height: 1.5;
}

.productInformation p.quantity{
    font-size:12px;
    font-weight: 600;
}

.cartItemDropDownMenu{
    width:300px;
    border-radius: 2px;
    margin-top:3px;
    box-shadow:var(--box-shadow-2);
    left:-50px;
}

.cartItemToggleButton{
    font-size: 13px !important;
    font-weight: 500;
    color:var(--primary-color);
}

.cartItemToggleButton span{
    margin-right: 3px;
}

.cartItemToggleButton.buttonToggled svg{
    transform:rotate(-180deg);
}
.cartItemToggleButton svg{
    transition: all .1s ease-in;
    height: 14px;
}

.itemTotal{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.itemTotal h6{
    font-size:13px;
    font-weight: 700;
    margin-left: 5px;
    color:var(--color-dark-grey);
    line-height: 1;
}

.itemTotal h6.totalPrice{
    text-decoration: line-through;
    display: block;
    opacity: 0.6;
}