.mainNavContainer{
    width: 100%;
    background: #fff;
    box-shadow: 0 0 16px rgba(0,0,0,0.15);
    /*border: 1px solid #f6f5f6;*/
    /*border: 1px solid #fcfcfc;*/
    /*box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;*/
    /*box-shadow:0px 2px 10px rgb(220 220 220);*/
    z-index:3;

}


.mainNavBar{
    flex-grow: 1;
    justify-content:end;
    height:80px;
}
.mainNavBarMenu li a,
.mainNavBarMenu li button{
    display: block;
    color:var(--color-black);
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 20px 10px;
    transition: all .15s ease-in;
}

.mainNavBarMenu li a:hover{
    color: var(--primary-color);
}

.mainNavBarUser{
    margin-left: 30px;
}
.mainNavBarUser > li > a,
.mainNavBarUser > li > button{
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 40px;
    height: 40px;
    color:var(--color-black);
}

.mainNavBarUser > li > a > svg,
.mainNavBarUser > li > button > svg{
    height:14px;
}

.mainNavBarUserCheckout{
    position:relative;
}

.mainNavBarUserCheckout > a,
.mainNavBarUserCheckout > button{
    background-color:var(--color-grey);
    border-radius: 50px;
}


.checkoutItems{
    position:absolute;
    top:-8px;
    right:-5px;
    height:22px;
    width:22px;
    background-color:var(--primary-color);
    border-radius:50%;
    color:var(--color-white);
    display:flex;
    align-items: center;
    justify-content:center;
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
}

.cartItemBackdrop{
    position:fixed;
    left:0;
    top:0;
    width:100vw;
    height:100vh;
    z-index:10;
    background-color:transparent;
    cursor:pointer;
}

.hamburgerContainer{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left:40px;
    display: none;
    cursor:pointer;
}
.hamburgerContainer > svg{
    height:24px
}


@media only screen and (max-width:  991px)
{
    .hamburgerContainer{
        display: flex;
    }
}

@media (max-width: 350px) {
    .mainNavBarUser > li > a,
    .mainNavBarUser > li > button{
        width: 35px;
        height: 35px;
    }

    .hamburgerContainer{
        margin-left:20px;
    }

    .checkoutItems{
        top:-10px;
        right:-7px;
    }
}
