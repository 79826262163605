
.searchDropdown{
    position:absolute;
    visibility:hidden;
    top:100%;
    right: -28px;
    background-color:#fff;
    width: 350px;
    margin-top:3px;
    border-radius: 4px;
    -webkit-box-shadow: 0 10px 70px rgba(0,0,0,0.15);
    box-shadow: 0 10px 70px rgba(0,0,0,0.15);
    z-index: 9999;
    overflow: hidden;
    transition:all .15s ease-in-out;
    opacity: 0;
}

.searchDropdown.visible{
    opacity: 1;
    visibility:visible;
}

.searchDropdown > div{
    padding: 15px;
    position: relative;
    max-height: 450px;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.searchDropdown > div::-webkit-scrollbar {
    display: none;
}

@media (max-width:560px) {
    .searchDropdown{
        width:300px;
        right: 0;
    }
}
