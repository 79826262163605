
.hamburgerMenu{
    position:fixed;
    width: 400px;
    height:100vh;
    top:0;
    right:-400px;
    background-color:rgba(255,255,255,0.95);
    z-index: 9999;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.hamburgerMenu.activeHamburgerMenu{
    right:0;
}

.hamburgerClose{
    position:absolute;
    top:5px;
    right:15px;
    padding:10px;
    z-index:1;
    cursor:pointer;
}

.hamburgerClose svg{
    height:24px;
    color:var(--color-black);
}

.hamburgerMenuContent{
    padding-top: 60px;
    padding-right:20px;
    width:100%;
    height:100%;
}

.hamburgerMenuNav{
    display: flex;
    flex-direction: column;
}

.menuItem > a{
    border-bottom: 1px solid  var(--color-off-white);
    text-align: right;
}

.menuItem a, .menuItem a:hover{
    font-size:14px;
    display: block;
    text-transform:uppercase;
    line-height:50px;
    height:50px;
    font-weight:500;
    color:var(--color-black);
}

.menuItem a:active, .menuItem a:focus,
.menuItem a:visited, .menuItem a:focus svg,
.menuItem a:active svg, .menuItem a:visited svg
{
    opacity:0.7;
}
.menuItem a svg{
    height:14px;
    margin-left:5px;
    font-weight:400;
    color:var(--color-black);
}

.menuItem .MenuSelection{
    list-style: none;
    max-height: 0;
    z-index: 1;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .4s ease-in-out;
    overflow: hidden;
    position:relative;
}

.menuItem.menuItemActive .MenuSelection{
    /*display:block;*/
    max-height: 500px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .4s ease-in-out;
}
.menuItem a svg{
    transition: all .4s ease-in-out;
}
.menuItem a svg:nth-child(1){
    display: none;
    opacity: 0;
}
.menuItem.menuItemActive a svg:nth-child(1){
    display: inline-block;
    opacity: 1;
}
.menuItem.menuItemActive a svg:nth-child(2){
    display: none;
    opacity: 0;
}

.menuItem a svg:nth-child(2){
    display: inline-block;
    opacity: 1;
}
.MenuSelection li{
    text-align:right;
    width:100%;
    padding-right:15px;
    padding-left:15px;

}
.MenuSelection li > a{
    border-bottom: 1px solid  var(--color-off-white);
}
.MenuSelection li a{
    font-size:14px;
    text-transform:uppercase;
    line-height:50px;
    height:50px;
    font-weight:500;
}