
.formGroup{
    margin-bottom: 10px;
}
.formGroup label{
    margin-bottom: 5px;
    color: var(--color-dark-grey);
    font-weight: 500;
    font-size: 0.9rem;
}

.formGroup.mandatory > label::after{
    content:'*';
    color: red;
    margin-left: 3px;
    font-weight: 600;
    font-size: 16px;
    display: inline-block;
}

.formGroup p{
    margin-bottom: 0;
}