.footer{
    background-color: #0e121a;
    /*background-color: var(--color-black);*/
    color:#fff;
    padding: 15px 0 0px 0;
}

.footerSection{
    width: 100%;
}

.footerSection h3{
    text-transform: uppercase;
}

.footerMenu{
    list-style:none;
    padding:0;
    display: flex;
    justify-content: center;
}

.footerMenu li a{
    color: var(--color-white);
    font-size: 13px;
    transition:all .15ms ease-in;
    vertical-align:middle;
}

.topLinks li a{
    font-size: 15px;
}

.footerMenu li a:hover{
    opacity: 1;
}
.subscribe h4{
    text-transform: capitalize;
}

.subscribe p{
    font-size: 13px;
}

.subscribeInput{
    background-color:#161a29;
    border-radius:0;
    color:#fff;
    border:none;
    outline:none;
    box-shadow:none;
    height:40px;
}
.subscribeInput input{
    height:40px;
    color:#fff;
    font-size: 14px;
}
.subscribeInput input::-webkit-input-placeholder{
    color:#fff;
    opacity: 0.7;
    font-size: 14px;
    text-transform: capitalize;
}

.subscribeBtn {
    border-radius: 0;
    background-color:var(--primary-color);
    border:1px solid var(--primary-color);
}
.subscribeBtn:hover{
    border:1px solid var(--primary-color);
    background-color:var(--primary-color);
}

.copyright{
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 15px;
}
.socialMedia{
    display:flex;
    justify-content: center;
}
.socialMedia li a{
    color:#fff;
}
.socialMedia li a svg{
    width: 35px;
    height: 35px;
}

.socialMedia li:not(:last-child){
   margin-right: 18px;
}

.socialMedia li a:hover{
    opacity: 1;
}

.socialMedia .hash-tags{
    margin-right: 10px !important;
}

.socialMedia .hash-tags a{
    font-size: 1rem;
}

.socialMedia .hash-tags a:hover{
    color: var(--primary-color);
}

@media (max-width:767px)
{
    .subscribe{
        margin-top:1rem;
        margin-bottom:1rem;
    }

    .footerMenu{
        display: block;
        text-align: center;
    }

    .subscribeBtn {
        float: left;
    }
}
