
.dropDownToggle{
    background-color:transparent;
    border: none;
    font-size:14px;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
