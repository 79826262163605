.textField{
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid #e2e5ec;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: all .15s ease-out;
    border-radius:2px;
    display:flex;
    align-items: center;
    padding: .325rem .75rem;
    flex-direction: row;
    justify-content: center;
}
.textField:focus,
.textField:focus-within
{
    border: 1px solid rgba(125, 188, 64, 1);
    box-shadow: 0 0 0 0.15rem rgba(125, 188, 64, 0.4);
}

/* Change the white to any color */
.textField input:-webkit-autofill,
.textField input:-webkit-autofill:hover,
.textField input:-webkit-autofill:focus,
.textField input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.textField.smallTextField{
    padding: .17rem .5rem;
}

.textField input, select.textField{
    display: block;
    width: 100%;
    font-size: 1rem;
    font-weight: 500;
    color: var(--color-dark-grey);
    line-height: 1.5;
    flex-grow: 1;
    vertical-align: middle;
    background-color:transparent;
}

select.textField{
    line-height: 1.6;
}

.textField.smallTextField input, .selectField.smallTextField{
    font-size: 0.85rem;
}

.textField  input::-webkit-input-placeholder {
    color:var(--color-black);
    opacity: 0.5;
}
.textField button{
    display:flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border:none;
    padding:0;
}
.textField button svg{
    height:18px;
    width:25px !important;
    color: #b1b8c3;
    font-weight: 800;
    padding-left:0.15rem;
    padding-right:0.15rem;
    cursor: pointer;
}






.textField input, .textField input:active,
.textField input:focus
{
    border:none;
    box-shadow:none;
    outline:0;
}

.selectField:active,
.selectField:focus
{
    border: 1px solid rgba(125, 188, 64, 1);
    box-shadow: 0 0 0 0.15rem rgba(125, 188, 64, 0.4);
    outline:0;
}