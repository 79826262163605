
.radioRoot{
    display: inline-flex;
    position: relative;
    align-items: center;
    justify-content: center;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    padding: 9px;
    border-radius: 50%;
    color:var(--color-dark-grey);
}

.radioChecked{
    color:var(--primary-color);
}

.radioRoot input{
    position: absolute;
    width: 100%;
    height:100%;
    opacity: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
}

.radioIcons{
    position:relative;
    display:flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}

.radioIcon{
    width: 16px;
    height: 16px;
    position:absolute;
    webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: inline-block;
    fill: transparent;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1.1rem;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.radioIcon.radioIconInner{
    width: 8px !important;
    height: 8px;
    transform:scale(0) !important;
}

.radioChecked .radioIcon.radioIconInner{
    transform:scale(1) !important;
}