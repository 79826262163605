
.dropDownMenu{
    position:absolute;
    top: 100%;
    left: 0;
    z-index: 99999;
    background-color: #fff;
    visibility: hidden;
    opacity: 0;

}

.dropDownMenu.isOpen{
    -webkit-transition: visibility 200ms linear, opacity 150ms linear;
    transition: visibility 200ms linear, opacity 150ms linear;
    visibility: visible;
    opacity: 1;
}