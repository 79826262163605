

.cartDropdownLoader{
    width: 100%;
    display: flex;
    align-items: flex-start;
    height: 70px;
}

.cartDropdownLoader svg{
    max-width: 100%;
    height:auto;
}