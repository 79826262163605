
.loginModalContent{
    max-width: 450px;
}



.modalHeader{
    position:relative;
    padding:20px 20px;
}

.modalHeader  button{
    position: absolute;
    right:20px;
    top:20px;

}

.loginHeader{
    display: flex;
    align-items: center;
}


.modalHeaderIcon{
    background-color: rgba(125, 188, 64, 0.2);
    padding:15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:12px;
}

.modalHeaderIcon svg{
    height:20px;
    color:var(--primary-color);
}

.modelHeaderTitle{
    display: flex;
    flex-direction: column;
    margin-left: 15px;
}

.modelHeaderTitle h4{
    font-size:1.5rem;
    margin-bottom:0;
    font-weight:600;
    color:#444;
}

.modelHeaderTitle p{
    margin-bottom:0;
    font-size:0.9rem;
    color:var(--color-dark-grey);

}

.loginModalBody{
    padding:20px;
}

.forgotPassword{
    margin-bottom:0;
    font-size:0.9rem;
    font-weight:500;
}

.DisclaimerContainer{
    margin-top:20px;
    margin-bottom:20px;
}
.DisclaimerContainer p{
    font-size:0.80rem;
    margin-bottom: 5px;
    font-weight:500;
}
.DisclaimerTitle{
    font-size: 1rem;
    display:flex;
    align-items: center;
}

.DisclaimerTitle svg{
    height: 16px;
    margin-right: 5px;
}

