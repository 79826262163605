
.topNav{
    background-color:var(--color-black);
}

.topNavRight > ul  li:not(:last-child){
    border-right: 1px solid var(--color-border-light);
}

.topNavLeft > ul >  li:not(:first-child){
    border-left: 1px solid var(--color-border-light);
}

.topNavLeft svg{
    width: 0.875em;
}


.topNavItem{
    min-width: 40px;
    padding-left:15px;
    padding-right:15px;
    vertical-align:middle;
}

.topNavItem a,
.topNavItem button
{
    height: 40px;
    color: var(--color-white);
    line-height: 40px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    display:flex;
    justify-content:center;
    align-items: center;
}

.topNavItem a:hover,.topNavItem button:hover,
.topNavItem a.active{
    color: var(--primary-color) !important;
}

.topNavItem a span,
.topNavItem button span
{
    margin-left: 5px;
}

.topNavSelection{
    position: relative;
}

.topNavSelectionMenu
{
    position: absolute;
    top:120%;
    left:0;
    margin:0;
    min-width:150px;
    background-color: var(--color-white);
    box-shadow: 0 0 25px rgba(63, 78, 100, 0.15);
    -webkit-transition: opacity .3s ease;
    -moz-transition: opacity .3s ease;
    -ms-transition: opacity .3s ease;
    -o-transition: opacity .3s ease;
    transition: all .3s ease;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    padding-bottom: 10px;
}
.topNavSelection.topNavSelectionActive .topNavSelectionMenu
{
    top:100%;
    visibility:visible;
    opacity: 1;
}

.topNavSelectionMenu li{
    padding-left:10px;
    padding-right:10px;
    line-height:6;
    height:45px;
    width:100%;
}
.topNavSelectionMenu li a,
.topNavSelectionMenu li button
{
    padding-left: 10px;
    display: flex;
    color: #232530;
    font-size: 13px;
    text-transform: uppercase;
    -webkit-transition: opacity .3s ease;
    -moz-transition: opacity .3s ease;
    -o-transition: opacity .3s ease;
    transition: all .3s ease;
    justify-content: flex-start;

}
.topNavSelectionMenu li:not(:last-child){
    border-bottom: solid 1px #ddd;
}

@media (max-width: 420px) {
    .navFull{
        width: 100%;
    }
    .navFull:not(:last-child)
    {
        border-bottom: 1px solid var(--color-border-light);
    }
}


@media (max-width: 420px) {
    .topNavItem{
        padding-left: 7px;
        padding-right: 7px;
    }
}
